import { Mixpanel } from '@/app/utils/mixpanel';
import { datadogRum } from '@datadog/browser-rum';

export const REFERRER_COOKIE_NAME = 'motivated:referrer';
export const LANDING_PAGE_COOKIE_NAME = 'motivated:landing_page';
export const LANDING_PAGE_ATTRIBUTES_COOKIE_NAME =
  'motivated:landing_page_attr';
export const FIRST_VISIT_COOKIE_NAME = 'motivated:first_visit_epoch';
export const UTM_SOURCE_COOKIE_NAME = 'motivated:utm_source';

/**
 * gets cookie in the browser
 */
export function getCookie(name: string) {
  if (typeof document === 'undefined' || !name) {
    return null;
  }

  const cookieValue = document.cookie
    .split(';')
    .find((item) => item.trim().startsWith(`${name}=`))
    ?.split('=')
    // skip empty strings
    .filter(Boolean);

  if (cookieValue && cookieValue.length > 2) {
    return cookieValue.slice(1).join('=');
  }

  return cookieValue?.slice(1).join();
}

export function setUserIdentify(
  customerId: string,
  email: string | undefined = undefined,
) {
  if (typeof window === 'undefined') {
    return;
  }
  const isDatadogEnabled = process.env.NEXT_PUBLIC_DATADOG_ACTIVE === 'true';
  const landingPage = getCookie(LANDING_PAGE_COOKIE_NAME);
  const referrer = getCookie(REFERRER_COOKIE_NAME);

  console.log('Motivated setIdentify customerId: ' + customerId, {
    customerId,
    landingPage: landingPage ? atob(landingPage) : '',
    referrer: referrer ? atob(referrer) : '',
  });

  if (isDatadogEnabled) {
    datadogRum.setUser({
      id: customerId,
      mixpanelId: Mixpanel.getDistinctId(),
    });
  }

  Mixpanel.setIdentify(customerId);
  let windowContext = window as any;
  windowContext.motivatedCustomerId = customerId;

  if (email) {
    windowContext.motivatedEmail = email;
  }
}

export function saveReferrer(referrer: string | undefined | null) {
  if (typeof document === 'undefined') {
    return;
  }
  if (getCookie(REFERRER_COOKIE_NAME)) {
    return;
  }

  const currentReferrer = referrer ? referrer : 'unknown';
  document.cookie = `${REFERRER_COOKIE_NAME}=${btoa(currentReferrer)}; path=/`;
}

export function saveLandingPage(landingPage: string | undefined | null) {
  if (typeof document === 'undefined') {
    return;
  }
  if (getCookie(LANDING_PAGE_COOKIE_NAME)) {
    return;
  }

  const currentPage = landingPage ? landingPage : 'unknown';
  document.cookie = `${LANDING_PAGE_COOKIE_NAME}=${btoa(currentPage)}; path=/`;

  const currentEpochTime = Math.trunc(new Date().getTime() / 1000).toString();

  document.cookie = `${FIRST_VISIT_COOKIE_NAME}=${currentEpochTime}; path=/`;
}

export function saveLandingPageAttributes({
  trackingAttributes,
  lpSlug,
  pageUrl,
}: {
  trackingAttributes: Record<any, any>;
  lpSlug: string;
  pageUrl: string;
}) {
  if (typeof document === 'undefined') {
    return;
  }
  if (getCookie(LANDING_PAGE_ATTRIBUTES_COOKIE_NAME)) {
    return;
  }

  const savedLandingPage = getCookie(LANDING_PAGE_COOKIE_NAME) ?? '';

  try {
    // this can run before the landing page cookie is saved so we use the current url
    const landingPage = savedLandingPage ? atob(savedLandingPage) : pageUrl;
    const lpUrl = new URL(landingPage);
    // adds leading slash as slug doesn't have it
    if (lpUrl.pathname.toLowerCase() !== `/${lpSlug}`) {
      return;
    }
  } catch (e) {
    console.log('Failed to parse landing page cookie', e);
    return;
  }

  document.cookie = `${LANDING_PAGE_ATTRIBUTES_COOKIE_NAME}=${btoa(JSON.stringify(trackingAttributes))}; path=/`;
}

export function saveUtmSource(currentUrlQs: string | undefined | null) {
  if (typeof document === 'undefined') {
    return;
  }

  if (!currentUrlQs) {
    return '';
  }

  const searchParams = new URLSearchParams(currentUrlQs);
  const utmSource = searchParams.get('utm_source');

  if (!utmSource) {
    return;
  }

  if (getCookie(UTM_SOURCE_COOKIE_NAME)) {
    return;
  }

  document.cookie = `${UTM_SOURCE_COOKIE_NAME}=${utmSource}; path=/`;
}

/**
 * url is hardcoded and depend on `process.env.NEXT_PUBLIC_PORTAL_HOST`
 */
export async function getProfile(
  signal?: AbortSignal,
  throwAnErrorIfNotConnected: boolean = true,
) {
  let res;
  try {
    const CHECK_URL = new URL(
      '/api/user/profile',
      process.env.NEXT_PUBLIC_PORTAL_HOST ?? '',
    ).toString();

    res = await fetch(CHECK_URL, { credentials: 'include', signal });
  } catch (e: any) {
    if (e.name === 'AbortError') {
      console.debug('Aborted fetching profile');
      return;
    }

    console.error('Failed to fetch profile', e);
    return;
  }

  if (!res.ok) {
    if (res.status === 401) {
      if (throwAnErrorIfNotConnected) {
        console.error('User is not logged in, status: ' + res.status);
      }
    } else {
      console.debug('Failed to fetch profile, status: ' + res.status);
    }
    return;
  }

  try {
    const json = await res.json();
    if (json?.email && json?.customerId) {
      return json;
    }
  } catch (e) {
    console.error('Failed to parse profile json', e);
  }
  // null means request succeeded but no profile returned
  // for example user unauthenticated
  // undefined means request failed
  console.error('User authenticated but no profile returned');
  return null;
}

/**
 * urls are hardcoded and depend on `process.env.NEXT_PUBLIC_PORTAL_HOST`
 */
export async function loginUser({
  name,
  password,
}: {
  name: string;
  password: string;
}) {
  try {
    const { csrfToken } = await (
      await fetch(`${process.env.NEXT_PUBLIC_PORTAL_HOST}/api/auth/csrf`, {
        credentials: 'include',
      })
    ).json();

    const loginResponse = await fetch(
      `${process.env.NEXT_PUBLIC_PORTAL_HOST}/api/auth/callback/dashboard-login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          csrfToken,
          email: name,
          password,
          json: true,
          // TODO can we avoid redirect altogether?
          // we don't want to be redirected anywhere
          // this is a safe endpoint that returns some json and doesn't require anything like cors
          callbackUrl: '/api/auth/providers',
        }),
      },
    );

    if (!loginResponse.ok) {
      console.error('Login response is not ok, user: ' + name);
      return null;
    }

    return await getProfile();
  } catch (e) {
    console.error('Failed to login user with email: ' + name, e);
    return null;
  }
}

/**
 * urls are hardcoded and depend on `process.env.NEXT_PUBLIC_PORTAL_HOST`
 */
export async function registerUser({
  name,
  password,
  landingPage,
  referrer,
}: {
  name: string;
  password: string;
  landingPage: string;
  referrer: string;
}) {
  try {
    const registerResponse = await fetch(`/api/user/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: name,
        password,
        landingPage,
        referrer,
      }),
    });

    if (!registerResponse.ok) {
      return null;
    }

    return await registerResponse.json();
  } catch (e) {
    console.error('registerUser error', e);
    return null;
  }
}

export async function registerOtpUser({
  name,
  landingPage,
  referrer,
  wizardTrackingAttributes,
  lpTrackingAttributes,
}: {
  name: string;
  landingPage: string;
  referrer: string;
  wizardTrackingAttributes: any;
  lpTrackingAttributes: string;
}) {
  try {
    const registerResponse = await fetch(`/api/user/register-otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: name,
        landingPage,
        referrer,
        wizardTrackingAttributes,
        lpTrackingAttributes,
      }),
    });

    if (!registerResponse.ok) {
      return null;
    }

    return await registerResponse.json();
  } catch (e) {
    console.error('registerUser error', e);
    return null;
  }
}

export async function requestOtp(params: {
  userId: string;
  resend?: boolean;
  domain?: string;
}) {
  try {
    const requestResponse = await fetch(`/api/user/request-otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (!requestResponse.ok) {
      return null;
    }

    return await requestResponse.json();
  } catch (e) {
    console.error('requestOtp error', e);
    return null;
  }
}

export async function loginOtpUser(params: { userId: string; otp: string }) {
  try {
    const loginResponse = await fetch(`/api/user/login-otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (!loginResponse.ok) {
      return null;
    }

    return await loginResponse.json();
  } catch (e) {
    console.error('loginOtpUser error', e);
    return null;
  }
}
